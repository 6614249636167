
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    setCurrentPageTitle("Sign-in");
    (document.querySelector("title") as HTMLTitleElement).innerHTML = "Sign-in";

    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const showPass = ref<boolean>(false);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().required().label("Username"),
      password: Yup.string().min(6).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            // Swal.fire({
            //   text: "Login Successfull",
            //   icon: "success",
            //   buttonsStyling: false,
            //   confirmButtonText: "Ok, got it!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-primary",
            //   },
            // }).then(function () {
            // Go to page after successfully login
            router.push({ name: "resume" });
            // });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };

    // Show / Hide Password
    const showPassword = () => {
      showPass.value = !showPass.value;
    };

    return {
      onSubmitLogin,
      showPassword,
      showPass,
      login,
      submitButton,
    };
  },
});
